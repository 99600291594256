import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Kontakt" description="Reden wir über Ihr Projekt. Sie haben den Plan, wir das Know-How – lassen Sie uns loslegen!" />
    <Container className="mb-2xl md:text-lg md:flex md:items-start lg:text-xl">
      <header className="gutter my-screen-lg md:w-1/2">
        <h1 className="mt-0">
          Wir können über alles reden. 
          <span className="ml-3 text-2xl text-white">Fast alles.</span>
        </h1>
      </header>
      <div className="my-screen-lg gutter md:w-1/2">
        <div className="my-lg">
          <address>
            <strong>{data.info.company_name}</strong><br />
            {data.info.address}<br />
            {data.info.post_code} {data.info.city}
          </address>

          {data.info.directions_link &&
          <a href={data.info.directions_link} target="_blank" rel="noopener noreferrer" className="text-white hover:text-brown-light">
            Route planen
            <FontAwesomeIcon icon={['fal', 'angle-right']} fixedWidth />
          </a>
          }
        </div>

        <ul className="my-lg">
          <li>
            <a href={ `tel:${data.info.phone.machine}` } className="hover:text-white">
              { data.info.phone.human }
            </a>
          </li>
          <li>
            <a href={ `mailto:${data.info.email}` } className="hover:text-white">
              { data.info.email }
            </a>
          </li>
        </ul>
      </div>
    </Container>
    <ContactUs slug="britta-kretschmann" />
  </Layout>
)

export const query = graphql`
  query {
    info: dataJson {
      company_name
      city
      email
      post_code
      phone {
        human
        machine
      }
      address
      directions_link
    }
  }
`

export default Page
